import { Injectable } from '@angular/core';
import { Router, CanActivate } from '@angular/router';
import { UserService } from './user.service';

@Injectable()
export class UserGuardService implements CanActivate {
  constructor(private router: Router, private userService: UserService) {}

  canActivate(): boolean {
    console.log(this.userService);
    if (!!this.userService.user) {
      console.log("Login guard false");
      return true;
    } else {
      console.log("Login guard true");
      this.router.navigateByUrl('/');
      return false;
    }
  }
}