import { Component, OnInit } from '@angular/core';
import { ClrDatagridStateInterface } from "@clr/angular";
import { HttpClient } from "@angular/common/http";
import { ProvidersService } from '../../core/services/providers.service';
import { FormGroup, FormControl, Validators} from "@angular/forms";
import { UserService } from '../../core/services/user.service';

@Component({
  selector: 'app-provider',
  templateUrl: './provider.component.html',
  styleUrls: ['./provider.component.css']
})
export class ProviderComponent implements OnInit {
  
  public providers;
  public detailedProviderId;
  public addProviderModal = false;
  public deleteProviderModal = false;
  
  public loading = true;
  public perPage = 50;
  public page = 1;
  public total = 0;

  public editMode = false;
  public providerToDelete = { fsid: 0, name: ''};

  form = new FormGroup({        
      name: new FormControl(''),
      id: new FormControl(''),
      summary: new FormControl(''),
      address1: new FormControl(''),
      address2: new FormControl(''),
      address3: new FormControl(''),
      city: new FormControl(''),
      districts: new FormControl(''),
      email: new FormControl(''),
      geopoint: new FormControl(''),
      livechat: new FormControl(''),
      postcode: new FormControl(''),
      telephone: new FormControl(''),
      url : new FormControl(''), 
      completed: new FormControl(false)
  });
  
  constructor(private providersService:ProvidersService, public userService: UserService, private http: HttpClient) { }

  ngOnInit() {
    this.getProviders();
  }

  public getProviders(){
    this.providersService.query()
      .subscribe((res: Array<any>) => {
        this.loading = false;
        this.total = res.length;
        this.providers = res;
       });
  }

  public openAdd() {
    this.form.reset();
    // this.providersService.newid()
    //   .subscribe((res) => {
    //     console.log(res[0]['id']);
    //     this.form.controls['id'].setValue(1*res[0]['id']+1);
    //   })
    this.addProviderModal = true;
    this.editMode = false;
  } 

 public onEdit(id){
    console.log(id);
    this.providersService.get(id)
      .subscribe((res: any) => {
         this.detailedProviderId = id;
        console.log(res);
         
        let districts = [];
         res.districts.forEach((item) => {
           districts.push(String(item.id));
         })

         res.livechat = res.livechat === true ? "true" : "false";

         res.districts = districts;
         console.log(res);
         delete res.created_by;
         delete res.updated_by;
         delete res.created_at;
         delete res.updated_at;
         delete res.oldproviderid;
         delete res.users;
         delete res.author;
         delete res.checkupdate;

         this.form.setValue(res);
         this.editMode = true;
         this.addProviderModal = true;
       }, (err) => {
         console.warn(err)
       });
  }

  public onDelete(id, name) {
    this.providerToDelete = { fsid: id, name: name };
    this.deleteProviderModal = true;
  }  

  public delete(id) {
    this.providersService.delete(id)
      .subscribe((res)=>{
        console.log('Deleted');
        console.log(res);
        this.addProviderModal = false;
        this.deleteProviderModal = false;

      }),
      ((err) => {
        console.warn(err)
      })
  }  

  public save() {
    let data = this.form.value;
    console.log(data);
    this.providersService.create(data)
    .subscribe((res)=> {
      console.log("Provider created")
      this.getProviders();
      this.form.reset();
      this.addProviderModal = false;
      this.editMode = false;
      //this.rebuildFE();
    },
    (err)=> {
      console.log("Error creating provider")
      console.warn(err)
    });

  }

  public update() {
    let data = this.form.value;

    this.providersService.update(this.detailedProviderId, data)
     .subscribe((res)=> {
        console.log("Provider updated")
        this.form.reset();
        this.getProviders();
        this.addProviderModal = false;
        // this.rebuildFE();
    },
    (err)=> {
      console.log("Error creating provider");
      console.warn(err);
    });

  }

  public cancel() {
    this.getProviders();
    this.addProviderModal = false;
    this.deleteProviderModal = false;
  }

  private rebuildFE() {

    this.http.post("https://api.netlify.com/build_hooks/5f7b43df3e2aa9012005a00f",
    {})
    .subscribe(
        (val) => {
            console.log("POST call to rebuild front end content success", 
                        val);
        },
        response => {
            console.log("POST call rebuild front end content error", response);
        },
        () => {
            console.log("The POST rebuild front end content observable is now completed.");
        });

  }

}