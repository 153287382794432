import { Component, OnInit } from '@angular/core';
import { ClrDatagridStateInterface } from "@clr/angular";
import { FormGroup, FormControl, FormArray, Validators} from "@angular/forms";

import {
  ArticleService,
  Article,
  CategoryService,
  Category
} from '@app/core';

// import { products } from '../products';

@Component({
  selector: 'app-article',
  templateUrl: 'article.component.html',
  styleUrls: ['article.component.css']
})
export class ArticleComponent implements OnInit {
  articles;
  selected = [];
  detailedarticleId;

  addarticleModal = false;
  editMode = false;

  mycategories;
  selectedAreas = [];
  slots = [];

  loading = true;
  perPage = 50;
  page = 1;
  total = 0;

  form = new FormGroup({        
        title: new FormControl(''),
        id: new FormControl(''),
        summary: new FormControl(''),
        text: new FormControl(''),
        startdate: new FormControl(''),
        enddate: new FormControl(''),
        createdate: new FormControl(''),
        updatedate: new FormControl(''),
        author: new FormControl(''),
        areas: new FormControl(''),
        categories: new FormControl('')
  });

  constructor(private articleService:ArticleService, private categoryService: CategoryService) { 

  }

  ngOnInit() {
    this.getArticles();
    // this.getCategories();
  }

  // getCategories() {
  //     this.categoryService.query().subscribe((actionArray) => {
  //        this.mycategories =  actionArray.map(item => {
  //          return {
  //            id: item.payload.doc.id,
  //            ...item.payload.doc.data()
  //          } as Category;
  //     });
  //    })
  // }

  get openModal() {
    return this.addarticleModal;
  }

  set openModal(value: boolean) {
    console.log("open modal");
    this.addarticleModal = value;

    if (!value) {
      // Put your cleanup code here
      // this.selectedAreas.splice(0);
      this.form.reset();
    }
  }


  getArticles(){
    this.articleService.query().subscribe((actionArray) => {
         // this.total = actionArray.length;
         this.articles = actionArray;
         this.loading = false;
    })
  }

  deleteArea(id) {
    (this.form.get("areas") as FormArray)['controls'].splice(id-1, 1);
    this.form.value.areas.splice(id-1, 1);
    console.log("Delete " + id);
  }

  onEdit(id){

    // this.articleService.get(id)
    //   .subscribe((res) => {
    //      this.detailedarticleId = id;
    //      let data = res;

    //      data.enddate = data.enddate.toDate().getDate() + "/" + (data.enddate.toDate().getMonth()+1) + "/" + data.enddate.toDate().getFullYear();
    //      data.startdate = data.startdate.toDate().getDate() + "/" + (data.startdate.toDate().getMonth()+1) + "/" + data.startdate.toDate().getFullYear();

    //      this.form.setValue(data);
         
    //      // reset all of the controls
    //      this.selectedAreas.splice(0);

    //      for(let i=0; i < data.areas.length; i++) {
    //        this.selectedAreas.push({ category: data.categories[i], name: data.areas[i]});
    //      } // TBD if categories don't equal areas!! error in index! - might want to look up

    //      this.editMode = true;
    //      this.addarticleModal = true;

    //    }, (err) => {
    //      console.warn(err)
    //    });
  }

  onDelete(id) {
    // this.articleService.delete(id)
    //   .then((res)=>{
    //     console.log('Deleted');
    //     console.log(res);
    //     (this.form.get("areas") as FormArray)['controls'].splice(0);
    //     this.form.reset();
    //   })
    //   .catch((err) => {
    //     console.warn(err)
    //   })

  } 

  public openAdd() {
    this.form.reset();
    this.selectedAreas.splice(0);
    this.addarticleModal = true;
    this.editMode = false;
  }

  onAddArea(categoryname, area) {
    console.log("Category " + categoryname + " " + area);
    this.selectedAreas.push({ category: categoryname, name: area});
  } 

  onDeleteArea(index) {
    console.log("Delete area - " + index);
    this.selectedAreas.splice(index, 1);
  }


  public onSave() {

    console.log(this.selectedAreas);
    
    let submittedCategories = [];
    let submittedAreas = [];

    for(let i=0; i < this.selectedAreas.length; i++) {
      submittedAreas.push(this.selectedAreas[i].name);
    }

    for(let i=0; i < this.selectedAreas.length; i++) {
      submittedCategories.push(this.selectedAreas[i].category);
    }

    this.form.value.areas = submittedAreas;
    this.form.value.categories = submittedCategories;

    let data = this.form.value;
    
    console.log(data);

    let GBparser = /(\d{2})\/(\d{2})\/(\d{4})/;

    let startmatch = data.startdate.match(GBparser);
    let endmatch = data.enddate.match(GBparser);

    data.startdate = new Date(startmatch[3], startmatch[2]-1, startmatch[1]);
    data.enddate = new Date(endmatch[3], endmatch[2]-1, endmatch[1]);
    data.createdate = new Date();
    data.updatedate = new Date();

    delete data.id;

    this.articleService.create(data)
    .subscribe((res)=> {
      console.log("article created");
      this.getArticles();
      // this.selectedAreas.splice(0);
      this.form.reset();
      this.addarticleModal = false;
    },
    (err)=> {
      console.log("Error creating article")
      console.warn(err)
    });
  }

  public onUpdate() {
    
    let submittedCategories = [];
    let submittedAreas = [];

    for(let i=0; i < this.selectedAreas.length; i++) {
      console.log(submittedAreas);
      submittedAreas.push(this.selectedAreas[i].name);
    }

    for(let i=0; i < this.selectedAreas.length; i++) {
      submittedCategories.push(this.selectedAreas[i].category);
    }

    this.form.value.areas = submittedAreas;
    this.form.value.categories = submittedCategories;

    let data = this.form.value;

    let GBparser = /(\d{2})\/(\d{2})\/(\d{4})/;

    let startmatch = data.startdate.match(GBparser);
    let endmatch = data.enddate.match(GBparser);


    data.startdate = new Date(startmatch[3], startmatch[2]-1, startmatch[1]);
    data.enddate = new Date(endmatch[3], endmatch[2]-1, endmatch[1]);

    data.updatedate = new Date();

    delete data.id;

    // this.articleService.update(this.detailedarticleId, data)
    //  .then((res)=> {
    //     console.log("article updated");
    //     // this.selectedAreas.splice(0);
    //     this.form.reset();
    //     this.getArticles();
    //     this.addarticleModal = false;
    //     this.detailedarticleId = null;
    //     this.editMode = false;
    // })
    // .catch((err)=> {
    //   console.log("Error updating article");
    //   console.warn(err);
    // });

  }

  public onCancel() {
    this.getArticles();
    this.addarticleModal = false;
    this.selectedAreas.splice(0);
    this.form.reset();
  }

}