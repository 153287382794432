import { environment } from '../../environments/environment';
import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { RouterModule } from '@angular/router';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { HttpClientModule } from '@angular/common/http';

import { ClarityModule } from '@clr/angular';

import { HeaderComponent } from './header/header.component';
import { HomeComponent } from './home/home.component';
import { HelpComponent } from './help/help.component';
import { LoginComponent } from './login/login.component';
import { SignupComponent } from './signup/signup.component';
import { ProvidersService } from './services/providers.service';
import { ServicesService } from './services/services.service';
import { CategoryService } from './services/categories.service';
import { ArticleService } from './services/articles.service';
import { TrainingService } from './services/training.service';
import { UserService } from './services/user.service';
import { UserGuardService } from './services/user-guard.service';

@NgModule({
  imports: [
    CommonModule,
    RouterModule,
    ReactiveFormsModule,
    FormsModule,
    HttpClientModule,
    ClarityModule
  ],
  declarations: [HeaderComponent, HomeComponent, LoginComponent, SignupComponent, HelpComponent],
  providers: [
    ProvidersService,
    ServicesService,
    CategoryService,
    ArticleService,
    UserService,
    TrainingService,
    UserGuardService
  ],
  exports: [HeaderComponent]
})
export class CoreModule {}
