export const Colors = [
  {
    name: 'Red',
    value: '#F52F22'
  },
  {
    name: 'Pink',
    value: '#F1428A'
  },
  {
    name: 'Purple',
    value: '#781DA0'
  },
  {
    name: 'Ultramarine',
    value: '#343DAC'
  },
  {
    name: 'Blue',
    value: '#0065AB'
  },
  {
    name: 'Cyan',
    value: '#00B7D6'
  },
  {
    name: 'Teal',
    value: '#00968B'
  },
  {
    name: 'Green',
    value: '#48960C'
  },
  {
    name: 'Yellow',
    value: '#ffdc0B'
  },
  {
    name: 'Orange',
    value: '#F57600'
  },
  {
    name: 'Red-orange',
    value: '#EE4A08'
  },
  {
    name: 'Warm-grey',
    value: '#80746D'
  },
  {
    name: 'Neutral-grey',
    value: '#737373'
  },
  {
    name: 'Cool-grey',
    value: '#61717D'
  }
];