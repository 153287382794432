import { Pipe, PipeTransform } from '@angular/core';
import { ProvidersService } from '../core/services/providers.service';


@Pipe({name: 'providerName', pure: false})
export class ProviderNamePipe implements PipeTransform {

	private providerName = 'Unknown';
	private cachedProviderId = '';

	constructor(private providersService: ProvidersService) { }

	transform(providerid: string): string {

		if(providerid !== this.cachedProviderId) {
			
			this.cachedProviderId = providerid;

			this.providersService.getProviders().subscribe((providers: Array<any>) => {
				for(let i=0; i < providers.length; i++) {
					
	           		if(providers[i].id === providerid) {
	           			this.providerName = providers[i].name;
	           		}
	           	}
			});
		}
		return this.providerName;

	}
}
