import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ReactiveFormsModule, FormsModule } from '@angular/forms';
import { ClarityModule } from '@clr/angular';
import { CoreModule } from '@app/core';

import { UserRoutingModule } from './user-routing';
import { UserComponent } from './user/user.component';

import { ProviderNamePipe } from './user.pipe';

@NgModule({
  imports: [
    CommonModule,
    FormsModule,
    ReactiveFormsModule,
    CoreModule,
    ClarityModule,
    UserRoutingModule
  ],
  declarations: [UserComponent, ProviderNamePipe]
})
export class UserModule {}