import { Pipe, PipeTransform } from '@angular/core';
import { ProvidersService } from '../core/services/providers.service';

@Pipe({name: 'labelColor'})
export class LabelColorPipe implements PipeTransform {
  transform(category: string): string {
  	
  	let color = '';

    switch (category) {
    	case 'Depressed':
    		color = 'blue';
    		break;
    	case 'Memory and learning difficulties':
    		color = 'light-blue';
    		break;
    	case 'Anxiety':
    		color = 'yellow';
    		break;
    	case 'Insomnia':
    		color = 'orange';
    		break;
    	case 'Insomnia':
    		color = 'orange';
    		break;
    	default:
    		color = 'purple';
    		break;
    }

    return 'label-' + color;
  }
}

// Need to look at this for performance

@Pipe({name: 'providerName', pure: false})
export class ProviderNamePipe implements PipeTransform {

	private providerName = 'Unknown';
	private cachedProviderId = '';

	constructor(private providersService: ProvidersService) { }

	transform(providerid: string): string {

		if(providerid !== this.cachedProviderId) {
			
			this.cachedProviderId = providerid;

			this.providersService.getProviders().subscribe((providers: Array<any>) => {
				console.log(providers);
				for(let i=0; i < providers.length; i++) {
					
	           		if(providers[i].id === providerid) {
	           			this.providerName = providers[i].name;
	           		}
	           	}
			});
		}
		return this.providerName;

	}
}

@Pipe({name: 'spaceCSV'})
export class SpaceCSVPipe implements PipeTransform {

	transform(csv): string {
		let output = '';
		for(let i=0; i < (csv.length); i++) {
			output = output + csv[i].name + ", ";
		}

		output = output.substr(0, output.length-2);

		return output;
	}
}