import { Component, OnInit, ViewChild } from '@angular/core';
import { ClrWizard, ClrDatagridStateInterface } from "@clr/angular";
import { FormGroup, FormControl, FormArray, Validators} from "@angular/forms";

import {
  TrainingService,
  UserService,
  Gender,
  AgeRanges,
  CaseQuestions,
  TrainingCase
} from '@app/core';


@Component({
  selector: 'app-training',
  templateUrl: 'training.component.html',
  styleUrls: ['training.component.css']
})
export class TrainingComponent implements OnInit {

  trainings;
  selected = [];
  detailedtrainingId;

  addtrainingModal = false;
  editMode = false;

  loading = true;
  perPage = 50;
  page = 1;
  total = 0;

  genders;
  ageserved;
  casequestions;

  public trainModal = false;
  public editTrainMode = false;
  public serviceLoading = true;
  public case: TrainingCase = { overview: { description: "" }, 
                                needs: { questions: [] }, 
                                demographics:  { genderspecific: "", agesserved: [] } 
                               };

  description;

  // for presentation of service options during training
  public services = [];
  numServices = 0;
  public currentService = { index: 0, id: "", name: "", summary: "", genderspecific: "", languages: "", ageserved: "", postcode: "", duration: "", areas: [] };

  public apps = [];
  numApps = 0;
  public currentApp = { index: 0, appPlatformId: 0, appName: "", description: "", platform: "", latestVersionReleasedOn: "" };

  public servicesPlus = { count: 0, services: [] };
  public servicesMinus = { count: 0, services: [] };
  public servicesDefinite = { count: 0, services: [] };

  public caseNeeds = { always: [], often: [], occasional: [] };

  public serviceView = true;
  public appView = false;

  public index = 0;

  constructor(private trainingService:TrainingService, private userService: UserService) { 

  }

  ngOnInit() {

    this.description = "";

    this.genders = Gender;
    this.ageserved = AgeRanges;
    this.casequestions = CaseQuestions;
    this.getTraining();
  }


  getTraining(){
    this.trainingService.query().subscribe((actionArray: Array<any>) => {
         this.total = actionArray.length;
         this.trainings = actionArray;
         this.loading = false;
    })
  }


  async onTrain(training){
    
    let serviceResults;
    let appResults;
    let maxResults = 10;

    console.log(training);
    this.case = training;

    for(let i=0; i < training.needs.questions.length; i++) {
      if(training.needs.questions[i] == '3')  this.caseNeeds.always.push(CaseQuestions[i].name);
      if(training.needs.questions[i] == '2')  this.caseNeeds.often.push(CaseQuestions[i].name);
      if(training.needs.questions[i] == '1')  this.caseNeeds.occasional.push(CaseQuestions[i].name);
    }

    serviceResults = await this.trainingService.serviceOptions(training);
    this.trainingService.appOptions(training).subscribe((apps : any) => {
      console.log(apps);
      this.apps = apps.results;
      this.numApps = this.apps.length;

      this.currentApp = { index: 0,...this.apps[0] };
    });

    maxResults = Math.min(maxResults, serviceResults.count);

    console.log(maxResults);
    console.log(serviceResults);

    for(let i=0; i < maxResults; i++) {
      let randIndex = Math.floor(Math.random()*serviceResults.count);
      console.log(randIndex);
      this.services.push(serviceResults.data[randIndex]);
    }

    //this.services = serviceResults.data;

    this.currentService = { index: 0,...this.services[0] };
    this.numServices = maxResults;
    console.log(this.currentService);


    
    this.serviceLoading = false;

    this.trainModal = true;

  }

  private saveVote(score) {

    let index;

    if(this.index < this.numServices) { 

      index = this.currentService.index;

        let timestamp = new Date().toISOString();
        this.servicesPlus.count++;
        this.servicesPlus.services.push(this.currentService.id);
        this.trainingService.saveTraining({ case: this.case, type: "service", service: this.currentService, vote: 0, ts: timestamp, trainerid: this.userService.user.email });
        index++;
        this.index++;
        this.currentService = { index: index,...this.services[index] };
  
      if(this.index == this.numServices) {
        this.serviceView = false;
        this.appView = true;
      }

    } else {

      index = this.currentApp.index;

      if(this.currentApp.index == (this.numApps-1)) {
        this.openTrainModal = false;
      }

      if(index < (this.apps.length-1)) {
        let timestamp = new Date().toISOString();

        this.trainingService.saveTraining({ case: this.case, type: "app", service: this.currentApp, vote: 0, ts: timestamp, trainerid: this.userService.user.email });

        index++;
        this.index++;
        this.currentApp = { index: index,...this.apps[index] };
      }

    }

  }

  onServicePlus() {

    this.saveVote(0);

  }

  onServiceMinus() {

    this.saveVote(-1);

    // if(index < this.services.length) {
    //   let timestamp = new Date().toISOString();
    //   this.servicesMinus.count++;
    //   this.servicesMinus.services.push(this.currentService.id);
    //   this.trainingService.saveTraining({ case: this.case, service: this.currentService, vote: -1, ts: timestamp, trainerid: this.userService.user.email });
    //   index++;
    //   this.currentService = { index: index,...this.services[index] };
    // }

    // if(index < this.numServices) { 
    //   this.serviceView = true;
    //   this.appView = false;
    // } else {
    //   this.serviceView = false;
    //   this.appView = true;    
    // }

    // console.log(this.servicesMinus);

  }

  onServiceDefinite() {
    this.saveVote(1);
    // let index = this.currentService.index;

    // if(index < this.services.length) {
    //   let timestamp = new Date().toISOString();
    //   this.servicesDefinite.count++;
    //   this.servicesDefinite.services.push(this.currentService.id);
    //   this.trainingService.saveTraining({ case: this.case, service: this.currentService, vote: 1, ts: timestamp, trainerid: this.userService.user.email });
    //   index++;
    //   this.currentService = { index: index,...this.services[index] };
    // }
    
    // if(index < this.numServices) { 
    //   this.serviceView = true;
    //   this.appView = false;
    // } else {
    //   this.serviceView = false;
    //   this.appView = true;    
    // }

    // console.log(this.servicesDefinite);

  }


  get openTrainModal() {
    return this.trainModal;
  }

  set openTrainModal(value: boolean) {
    this.trainModal = value;
    this.editMode = false;
    if (!value) {
      // Put your cleanup code here
      this.cancelTrainModal();
    }
  }

  public cancelTrainModal() {
    console.log("Reset training scores");

    this.servicesPlus = { count: 0, services: [] };
    this.servicesMinus = { count: 0, services: [] };
    this.servicesDefinite = { count: 0, services: [] };
    this.services = [];

    this.serviceView = true;
    this.appView = false;
    
    this.getTraining();

    this.services = [];
    this.numServices = 0;
    this.currentService = { index: 0, id: "", name: "", summary: "", genderspecific: "", languages: "", ageserved: "", postcode: "", duration: "", areas: [] };

    this.apps = [];
    this.numApps = 0;
    this.currentApp = { index: 0, appPlatformId: 0, appName: "", description: "", platform: "", latestVersionReleasedOn: "" };

    this.index = 0;

    // let trainingscore = {
    //   case: this.case,
    //   scores: { definite: this.servicesDefinite, maybe: this.servicesPlus, no: this.servicesMinus },
    //   trainer: this.userService.user.email
    // }
    // this.trainingService.saveTraining(trainingscore);

  }
  

}