import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { HttpClientModule } from '@angular/common/http';
import { ReactiveFormsModule, FormsModule } from '@angular/forms';
import { ClarityModule } from '@clr/angular';
import { CoreModule } from '@app/core';

import { ProviderRoutingModule } from './provider-routing';
import { ProviderComponent } from './provider/provider.component';

@NgModule({
  imports: [
    CommonModule,
    FormsModule,
    ReactiveFormsModule,
    CoreModule,
    ClarityModule,
    HttpClientModule,
    ProviderRoutingModule
  ],
  declarations: [ProviderComponent]
})
export class ProviderModule {}