import { Component, OnInit } from '@angular/core';
import { ClrDatagridStateInterface } from "@clr/angular";
import { FormGroup, FormControl, FormArray, Validators} from "@angular/forms";
import { UserService } from '../../core/services/user.service';
import { ProvidersService } from '../../core/services/providers.service';

@Component({
  selector: 'app-user',
  templateUrl: 'user.component.html',
  styleUrls: ['user.component.css']
})
export class UserComponent implements OnInit {
  users;
  selected = [];
  detaileduserId;

  adduserModal = false;
  editMode = false;

  loading = true;
  perPage = 50;
  page = 1;
  total = 0;

  providers;

  form = new FormGroup({        
        name: new FormControl(''),
        email: new FormControl(''),
        roles: new FormControl(''),
        providerid: new FormControl(''),
  });

  constructor(private userService:UserService, private providersService: ProvidersService,) { 

  }

  ngOnInit() {
    // this.getUsers();
  }

  get openModal() {
    return this.adduserModal;
  }

  set openModal(value: boolean) {
    this.adduserModal = value;
    if (!value) {
      // Put your cleanup code here

      this.form.reset();
    }
  }


  // getUsers(){
  //   this.userService.query().subscribe((actionArray) => {
  //        this.total = actionArray.length;
  //        console.log(actionArray);
  //        this.users = actionArray;
  //   })
  // }

  private getProviders(): void {
    this.providersService.getProviders().subscribe((providers) => {
      this.providers = providers;
    });
  }


  // onEdit(id){

  //   this.getProviders();

  //   this.userService.get(id)
  //     .subscribe((res) => {
  //        this.detaileduserId = id;

  //        console.log(res.data());
  //        // patch all of the values including the array - they will 
  //        // find a home in the Form Array
  //        this.form.patchValue({ id: id,...res.data()});
  //        this.editMode = true;
  //        this.adduserModal = true;

  //      }, (err) => {
  //        console.warn(err)
  //      });
  // }

  // onDelete(id) {
  //   this.userService.delete(id)
  //     .then((res)=>{
  //       console.log('Deleted');
  //       console.log(res);
  //       this.form.reset();
  //     })
  //     .catch((err) => {
  //       console.warn(err)
  //     })

  // } 

  public openAdd() {
    this.form.reset();
    this.adduserModal = true;
    this.editMode = false;
  } 


  public onSave() {
    let data = this.form.value;

    data.providerid = 1*data.providerid+0;
    console.log(data);

    delete data.id;

    // this.userService.create(data)
    // .then((res)=> {
    //   console.log("user created");
    //     this.getUsers();
    //     this.adduserModal = false;
    //     this.form.reset();
    //     this.editMode = false;
    // })
    // .catch((err)=> {
    //   console.log("Error creating user")
    //   console.warn(err)
    // });

      
  }

  public onUpdate() {
    let data = this.form.value;
    delete data.id;

    data.providerid = 1*data.providerid+0;
    console.log(data);

    // this.userService.update(this.detaileduserId, data)
    //  .then((res)=> {
    //     console.log("user updated");
    //     this.getUsers();
    //     this.adduserModal = false;
    //     this.form.reset();
    //     this.editMode = false;
    // })
    // .catch((err)=> {
    //   console.log("Error creating user");
    //   console.warn(err);
    // });

  }

  public onCancel() {
    // this.getUsers();
    this.adduserModal = false;
    this.form.reset();
  }

}