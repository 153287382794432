import { Injectable } from '@angular/core';
import { HttpHeaders, HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import { environment } from '../../../environments/environment';

import { Roles } from '../models/user';

export interface Userdata {
    id: number,
    username: string,
    email: string,
    provider: string,
    confirmed: boolean,
    blocked: boolean,
    role: {
        id: number,
        name: string,
        description: string,
        type: string,
        created_by: number,
        updated_by: number
    },
    created_by: number,
    updated_by: number,
    created_at: string,
    updated_at: string,
    providers: Array<number>
}

export interface User {    
  jwt: string,
  user: Userdata  
}
/*
 * Provides services for authentication
 */
@Injectable()
export class UserService {

  private _user: Userdata;
  private _roles: Roles;
  private _providerid: number;
  public _token: string;

  private apiHostUrl: string = environment.apiHostUrl;

  constructor(private http: HttpClient) {

    // initialise with no permissions/role
      this._roles = {  
        admin: false, 
        superuser: false,
        approve: false,
        availability: false,
        create: false,
        remove: false,
        edit: false,
        training: false,
      };

    // this.afAuth.authState.subscribe(user => {
    //   if (user) {
    //     this._user = user;
    //     this._roles = { 
    //       email: this._user.email, 
    //       admin: false, 
    //       superuser: false,
    //       approve: false,
    //       availability: false,
    //       create: false,
    //       remove: false,
    //       edit: false,
    //       training: false,
    //     };

    //     this._providerid = 0;

    //     this.firestore.collection("user", ref => ref.where('email', '==', this._user.email).limit(1))
    //       .get()
    //       .subscribe(userroles => {  

    //         userroles.forEach(documentSnapshot =>  {
    //           let roles = documentSnapshot.data().roles;
              
    //           console.log(documentSnapshot.data().providerid);
    //           this._providerid = documentSnapshot.data().providerid;

    //           // go through the roles array and set the booleans
    //           roles.forEach(role => {
    //               if(role == "admin") this._roles.admin = true;
    //               if(role == "superuser") this._roles.superuser = true;
    //               if(role == "approve") this._roles.approve = true;
    //               if(role == "availability") this._roles.availability = true;
    //               if(role == "create") this._roles.create = true;
    //               if(role == "remove") this._roles.remove = true;
    //               if(role == "edit") this._roles.edit = true;
    //               if(role == "training") this._roles.training = true;
    //               localStorage.setItem('user', JSON.stringify(this._user));
    //           });
    //         }) 
    //       });

        
    //   } else {
    //     localStorage.setItem('user', null);
    //   }
    // });
  }

  public get user(): Userdata {

    // if(!this._user) {
    //     if(this._token = localStorage.getItem('token')) {
    //       const headers: HttpHeaders = new HttpHeaders({
    //         'Content-Type': 'application/json',
    //         'Authorization': 'Bearer ' + this._token,
    //         'Accept': '*'
    //       });
      
    //       this.http.get(environment.apiHostUrl + 'users/me', { headers: headers}).subscribe((res: Userdata) => {
    //         this._user = res;
    //       });
    //     }  else {
    //       return null;
    //     }
    // } else {
      return this._user;  
    // }

  }

  public set user(user) {
    
    this._user = user;
  }

  public get roles() {

    // this._roles.forEach((role) => {
    //   role = true;
    // })
    return this._roles;  
  }

  public set roles(roles) {
    this._roles = roles;
  }

  public set token(token) {
    localStorage.setItem('token', token);
    this._token = token;  
  }

  public get providerid() {
    return this._providerid;  
  }

  public setRoles() {
    
    if(this._user.role.type === 'provider') {
      this._roles.admin = true;
      this._roles.edit = true;
      this._roles.training = true;
    }

    if(this._user.role.type === 'service') {
      this._roles.edit = true;
      this._roles.training = true;
    }

    if(this._user.role.type === 'hsc') {
      this._roles.training = true;
    }

  }

  // public query() {
  //   return this.firestore.collection("user").snapshotChanges();
  // }


  // public get(id): Observable <any> { 
  //    return this.firestore.collection("user").doc(id).get();
  // }

  // public update(id, user) : Promise<void> {

  //   let timestamp = new Date().toISOString();
  //   console.log("updated provider");
  //   this.firestore.collection("audit").add({collection: 'user', action: 'update', ts: timestamp, userid: this.user.email,
  //            ...user});
  //   return this.firestore.collection("user").doc(id).set(user);

  // }

  // public create(user) : Promise<DocumentReference> {
  //   console.log("created user");
  //   let timestamp = new Date().toISOString();
  //   let creatingUser = "public website";

  //   user.email = user.email.toLowerCase();

  //   // if(!(typeof this.user === 'undefined')) { 
  //   //   creatingUser = this.user.email;
  //   // }

  //   // this.firestore.collection("audit").add({collection: 'user', action: 'create', ts: timestamp, userid: creatingUser,
  //   //          ...user});
  //   return this.firestore.collection("user").add(user);
  // }

  // public delete(id) : Promise<void> {
  //   let timestamp = new Date().toISOString();
  //   this.firestore.collection("audit").add({collection: 'user', action: 'delete', ts: timestamp, userid: this.user.email, objectid: id });
  //   return this.firestore.collection("user").doc(id).delete();
  // }


  // Helper functions for headers

    //******************** Headers *************/
      private getNoAuthHeaders(): HttpHeaders {
        let headers: HttpHeaders = new HttpHeaders({ 'Content-Type': 'application/json' });
        return headers
    }

    private getAuthHeaders(): HttpHeaders {
        let headers: HttpHeaders = new HttpHeaders({
            'Content-Type': 'application/json',
            'X-MIND-Version': '0'
        });
        return headers
    }

    private getNoAuthOptions() {
        const headers = this.getNoAuthHeaders()
        return { headers: headers }
    }

    private getAuthOptions() {
        const headers = this.getAuthHeaders()
        console.log(headers)
        return { headers: headers }
    }


// Firestore Auth calls

    // Sign up with email/password
  SignUp(email, password) {

    console.log('Create user');
    
    const headers: HttpHeaders = new HttpHeaders({
      'Content-Type': 'application/json',
      'Accept': '*'
    });

    const accountInfo = {
        username: email,
        email: email,
        password: password
    }

    return this.http.post(this.apiHostUrl + 'auth/local/register', JSON.stringify(accountInfo), { headers: headers});
  
  }

  // Send email verfificaiton when new user sign up
  SendVerificationMail() {
    console.log('Send verification email');
    
    const headers: HttpHeaders = new HttpHeaders({
      'Content-Type': 'application/json',
      'Accept': '*'
    });

    const accountInfo = {
        email: this._user.email
    }

   this.http.post(this.apiHostUrl + 'auth/send-email-confirmation', JSON.stringify(accountInfo), { headers: headers}).subscribe(
      (res) => {
        console.log("Sent verification email");
        return true;
      },
      (error) => {
        console.log(error);
        return false;
      }
    )
  }

  // Reset Forgot password
  ForgotPassword(passwordResetEmail) {
    
    console.log('Password reset');
    
    const headers: HttpHeaders = new HttpHeaders({
      'Content-Type': 'application/json',
      'Accept': '*'
    });

    const accountInfo = {
        email: passwordResetEmail
    }

    return this.http.post(this.apiHostUrl + 'auth/forgot-password', JSON.stringify(accountInfo), { headers: headers});
  }

  /*
   * Method used by the UserGuardService to check the user prior to initializing the application
   */
  init() {
    // return Observable.create(observer => {

    //   this.http.get(`https://firebase.google.com/user`).subscribe(
    //     (user: User) => {
    //       this._user = user;
    //       observer.next(user);
    //       observer.complete();
    //     },
    //     error => {
    //       observer.error(error);
    //     }
    //   );
    // });
  }


  login(email:  string, password:  string) {

      // call to the Strapi user API

      console.log('Log in');
      const accountInfo = {
          identifier: email,
          password: password
      }

      const headers: HttpHeaders = new HttpHeaders({
        'Content-Type': 'application/json',
        'Accept': '*'
      });

      return this.http.post(this.apiHostUrl + 'auth/local', JSON.stringify(accountInfo), { headers: headers});

  }

  /*
   * Method to login, will automatically clear the logged in cache
   */
  logout() {

      localStorage.removeItem('user');
      localStorage.removeItem('token');
      this._user = null;
      this._token = null;
      var promise = new Promise((resolve, reject) => {
      });
      return promise;
  }
}
