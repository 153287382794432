import { Injectable } from '@angular/core';
import { HttpHeaders, HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import { Article } from '../models/article';
import { environment } from '../../../environments/environment';

import { UserService } from './user.service';

@Injectable()
export class ArticleService {

  private endpoint: string = 'articles';

  constructor(private http: HttpClient, private userService: UserService) {
  }

  /* 
   * @description   Get a list of of categories
   */

  public create(article: Article) {

    // article = { author: this.userService.user.user.id, ...article };

    return this.http.post(environment.apiHostUrl + this.endpoint, JSON.stringify(article));

  }

  
  public query() {
    console.log('Get all articles');

    const headers: HttpHeaders = new HttpHeaders({
      'Content-Type': 'application/json',
      'Authorization': 'Bearer ' + this.userService._token,
      'Accept': '*'
    });

    return this.http.get(environment.apiHostUrl + 'articles', { headers: headers});
  }

  public get(id: string) {
    console.log('Get an articles');

    const headers: HttpHeaders = new HttpHeaders({
      'Content-Type': 'application/json',
      'Authorization': 'Bearer ' + this.userService._token,
      'Accept': '*'
    });

    return this.http.get(environment.apiHostUrl + 'articles/' + id, { headers: headers});
  }

  // public update(id: string, article: Article) : Promise<void> {

  // }

  // public delete(id: string) : Promise<void> {
  //   return new Promise();
  // }
}