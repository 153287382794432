import { Component } from '@angular/core';
import { Router } from '@angular/router';

@Component({
  templateUrl: './help.component.html',
  styleUrls: ['./help.component.css']
})
export class HelpComponent {
  constructor() {}
}
