import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { UserGuardService } from '@app/core';

import { TrainingComponent } from './training/training.component';
import { CasesComponent } from './cases/cases.component';

const routes: Routes = [
  {
    path: 'cases',
    component: CasesComponent,
    canActivate: [UserGuardService]
  },
  {
    path: 'training',
    component: TrainingComponent,
    canActivate: [UserGuardService]
  }
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule]
})
export class TrainingRoutingModule {}