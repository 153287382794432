import { Component, OnInit } from '@angular/core';
import { ClrDatagridStateInterface } from "@clr/angular";
import { FormGroup, FormControl, FormArray, Validators} from "@angular/forms";

import {
  CategoryService,
  Category
} from '@app/core';

// import { products } from '../products';

@Component({
  selector: 'app-category',
  templateUrl: 'category.component.html',
  styleUrls: ['category.component.css']
})
export class CategoryComponent implements OnInit {
  categories;
  selected = [];
  detailedcategoryId;

  addcategoryModal = false;
  editMode = false;

  loading = true;
  perPage = 50;
  page = 1;
  total = 0;

  form = new FormGroup({        
        name: new FormControl(''),
        id: new FormControl(''),
        areas: new FormArray([ 
          new FormControl('')
        ]),
  });

  constructor(private categoryService:CategoryService) { 

  }

  ngOnInit() {
    this.getCategories();
  }

  get openModal() {
    return this.addcategoryModal;
  }

  set openModal(value: boolean) {
    this.addcategoryModal = value;
    if (!value) {
      // Put your cleanup code here
      (this.form.get("areas") as FormArray)['controls'].splice(0);
      this.form.reset();
    }
  }


  getCategories(){
    this.categoryService.query().subscribe((actionArray: Array<any>) => {
         this.total = actionArray.length;
         this.categories = actionArray.map(item => {
             return {
               id: item.payload.doc.id,
               ...item.payload.doc.data()
             } as Category;
          });
         this.loading = false;
    })
         // this.areas = res.areas;
         // console.log(res);
  }

  get areas(): FormArray { return this.form.get('areas') as FormArray; }

  addActivity() {
    this.areas.push(new FormControl());
  }

  deleteArea(id) {
    (this.form.get("areas") as FormArray)['controls'].splice(id-1, 1);
    this.form.value.areas.splice(id-1, 1);
    console.log("Delete " + id);
  }

  onEdit(id){

    this.categoryService.get(id)
      .subscribe((res) => {
         this.detailedcategoryId = id;

         // need to create the form elements for
         // areas then path them in

         // fill in the first control that is already there with 
         // the first value of the array (if it doesn't exist then blank)
         let activityFields = [];
         if(res.data().areas) {
           activityFields = res.data().areas;
         }
         
         // reset all of the controls
         (this.form.get("areas") as FormArray)['controls'].splice(0);

         // then iterate and make a new control for each value
         // in the array
         for(let i=0; i < activityFields.length; i++) {
             let activityFrmControl = new FormControl();
             this.areas.push(activityFrmControl);
         }
         // patch all of the values including the array - they will 
         // find a home in the Form Array
         this.form.patchValue({ id: id,...res.data()});
         this.editMode = true;
         this.addcategoryModal = true;

       }, (err) => {
         console.warn(err)
       });
  }

  onDelete(id) {
    // this.categoryService.delete(id)
    //   .then((res)=>{
    //     console.log('Deleted');
    //     console.log(res);
    //     (this.form.get("areas") as FormArray)['controls'].splice(0);
    //     this.form.reset();
    //   })
    //   .catch((err) => {
    //     console.warn(err)
    //   })

  } 

  public openAdd() {
    // this.createNew();
    this.form.reset();
    (this.form.get("areas") as FormArray)['controls'].splice(0);
    this.addcategoryModal = true;
    this.editMode = false;
  } 


  public onSave() {
    let data = this.form.value;

    console.log(data);
    // delete data.id;

    // this.categoryService.create(data)
    // .then((res)=> {
    //   console.log("service created");
    //   this.getCategories();
    //   (this.form.get("areas") as FormArray)['controls'].splice(0);
    //   this.form.reset();
    //   this.addcategoryModal = false;
    // })
    // .catch((err)=> {
    //   console.log("Error creating service")
    //   console.warn(err)
    // });

      
  }

  public onUpdate() {
    let data = this.form.value;
    // delete data.id;

    // this.categoryService.update(this.detailedcategoryId, data)
    //  .then((res)=> {
    //     console.log("category updated");
    //     (this.form.get("areas") as FormArray)['controls'].splice(0);
    //     this.form.reset();
    //     this.getCategories();
    //     this.addcategoryModal = false;
    //     this.detailedcategoryId = null;
    //     this.editMode = false;
    // })
    // .catch((err)=> {
    //   console.log("Error creating category");
    //   console.warn(err);
    // });

  }

  public onCancel() {
    this.getCategories();
    this.addcategoryModal = false;
    (this.form.get("areas") as FormArray)['controls'].splice(0);
    this.form.reset();
  }

}