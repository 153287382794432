import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ReactiveFormsModule, FormsModule } from '@angular/forms';
import { HttpClientModule } from '@angular/common/http';
import { ClarityModule } from '@clr/angular';
import { CoreModule } from '@app/core';

import { ServiceRoutingModule } from './service-routing';
import { ServiceComponent } from './service/service.component';

import { LabelColorPipe, ProviderNamePipe, SpaceCSVPipe } from './service.pipe';

@NgModule({
  imports: [
    CommonModule,
    FormsModule,
    ReactiveFormsModule,
    CoreModule,
    ClarityModule,
    HttpClientModule,
    ServiceRoutingModule
  ],
  declarations: [ServiceComponent, LabelColorPipe, ProviderNamePipe, SpaceCSVPipe]
})
export class ServiceModule {}