import { Observable } from 'rxjs';
import { HttpHeaders, HttpClient } from '@angular/common/http';
import { UserService } from './user.service';

import { environment } from '../../../environments/environment';


export class ProvidersService {


  constructor ( private userService: UserService, private http: HttpClient ) {

  }

  public create(provider) {
    console.log("created provider");

    const headers: HttpHeaders = new HttpHeaders({
      'Content-Type': 'application/json',
      'Authorization': 'Bearer ' + this.userService._token,
      'Accept': '*'
    });

    return this.http.put(environment.apiHostUrl + 'providers', JSON.stringify(provider), { headers: headers});

  }

  public query() {

    // call to the Strapi API

    console.log('Get all providers');

    const headers: HttpHeaders = new HttpHeaders({
      'Content-Type': 'application/json',
      'Authorization': 'Bearer ' + this.userService._token,
      'Accept': '*'
    });

    return this.http.get(environment.apiHostUrl + 'providers', { headers: headers});

  }

  public get(id) { 
     
    console.log('Get a provider');

    const headers: HttpHeaders = new HttpHeaders({
      'Content-Type': 'application/json',
      'Authorization': 'Bearer ' + this.userService._token,
      'Accept': '*'
    });

    return this.http.get(environment.apiHostUrl + 'providers/' + id, { headers: headers});

  }

  public update(id, provider)  {

    console.log('Update a provider');

    const headers: HttpHeaders = new HttpHeaders({
      'Content-Type': 'application/json',
      'Authorization': 'Bearer ' + this.userService._token,
      'Accept': '*'
    });

    console.log(provider);

    return this.http.put(environment.apiHostUrl + 'providers/' + id, JSON.stringify(provider), { headers: headers});

  }

  public delete(id) {
    console.log('Delete a provider');

    const headers: HttpHeaders = new HttpHeaders({
      'Content-Type': 'application/json',
      'Authorization': 'Bearer ' + this.userService._token,
      'Accept': '*'
    });

    return this.http.delete(environment.apiHostUrl + 'providers/' + id, { headers: headers});

  }

// To support insert with a unique number
  public newid() { 
  }

// To support the picker control
  public getProviders() {
    console.log('Get all providers');

    const headers: HttpHeaders = new HttpHeaders({
      'Content-Type': 'application/json',
      'Authorization': 'Bearer ' + this.userService._token,
      'Accept': '*'
    });

    return this.http.get(environment.apiHostUrl + 'providers', { headers: headers});

  }

}