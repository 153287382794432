import { FormControl, FormGroup } from "@angular/forms";
import { Observable } from 'rxjs';
import { HttpHeaders, HttpClient } from '@angular/common/http';
import { UserService } from './user.service';

import { environment } from '../../../environments/environment';

export class ServicesService {

  public services;

  constructor (private userService: UserService, private http: HttpClient ) {

  }

  public create(service) {

    console.log('Service create');
    service.provider = 1.0*service.provider;

    if(service.remote === '') service.remote = false;
    if(service.inperson === '') service.inperson = false;
    if(service.genderspecific === '') service.genderspecific = 'A';

    const headers: HttpHeaders = new HttpHeaders({
      'Content-Type': 'application/json',
      'Authorization': 'Bearer ' + this.userService._token,
      'Accept': '*'
    });

    return this.http.post(environment.apiHostUrl + 'services', JSON.stringify(service), { headers: headers });

  }

  public query() {

    console.log('Provider query');

    const headers: HttpHeaders = new HttpHeaders({
      'Content-Type': 'application/json',
      'Authorization': 'Bearer ' + this.userService._token,
      'Accept': '*'
    });

    return this.http.get(environment.apiHostUrl + 'services', { headers: headers});

  }

  public get(id): Observable <any> { 
    console.log('Provider query');

    const headers: HttpHeaders = new HttpHeaders({
      'Content-Type': 'application/json',
      'Authorization': 'Bearer ' + this.userService._token,
      'Accept': '*'
    });

    return this.http.get(environment.apiHostUrl + 'services/' + id, { headers: headers});
  }

  public update(id, service) {
    console.log('Service create');
    service.provider = 1.0*service.provider;

    const headers: HttpHeaders = new HttpHeaders({
      'Content-Type': 'application/json',
      'Authorization': 'Bearer ' + this.userService._token,
      'Accept': '*'
    });

    return this.http.put(environment.apiHostUrl + 'services/' + id, JSON.stringify(service), { headers: headers });
  }

  public toggleActivate(id, value) {

    const headers: HttpHeaders = new HttpHeaders({
      'Content-Type': 'application/json',
      'Authorization': 'Bearer ' + this.userService._token,
      'Accept': '*'
    });

    return this.http.put(environment.apiHostUrl + 'services/' + id, JSON.stringify({ deactivated: value}), { headers: headers });

  }

  public toggleBoost(id, value) {

    const headers: HttpHeaders = new HttpHeaders({
      'Content-Type': 'application/json',
      'Authorization': 'Bearer ' + this.userService._token,
      'Accept': '*'
    });

    return this.http.put(environment.apiHostUrl + 'services/' + id, JSON.stringify({ boost: value}), { headers: headers });

  }


  public delete(id) {

    const headers: HttpHeaders = new HttpHeaders({
      'Content-Type': 'application/json',
      'Authorization': 'Bearer ' + this.userService._token,
      'Accept': '*'
    });
    
    return this.http.delete(environment.apiHostUrl + 'services/' + id, { headers: headers });  
  }

// need to check the id is always the right datatype

  // public byprovider(id): Observable <any> { 
  //    return this.firestore.collection("services", ref => ref.where('provider', '==', id)).valueChanges();
  // }

}