import { Component, OnInit } from '@angular/core';
import { HttpClient } from "@angular/common/http";
import { ClrDatagridStateInterface } from "@clr/angular";
import { ServicesService } from '../../core/services/services.service';
import { ProvidersService } from '../../core/services/providers.service';
import { FormGroup, FormControl, Validators} from "@angular/forms";
import { UserService } from '../../core/services/user.service';

import {
  AgeRanges,
  Bame,
  Colors,
  CategoryService,
  Category,
  Gender,
  Languages
} from '@app/core';
import { identifierModuleUrl } from '@angular/compiler';


@Component({
  selector: 'app-service',
  templateUrl: 'service.component.html',
  styleUrls: ['service.component.css']
})

export class ServiceComponent implements OnInit {
  services;
  selected = [];
  detailedserviceId;
  providername = "";

  editMode = false;
  addserviceModal = false;

  loading = true;
  perPage = 50;
  page = 1;
  total = 0;

  mycategories;
  areas;
  selectedAreas = [];
  slots = [];

  providers;
  genders;
  languages;
  bame;
  ageserved;
  boost;
  deactivate;

  form = new FormGroup({
        id: new FormControl(''),   
        provider: new FormControl(''),        
        name: new FormControl(''),
        type: new FormControl(''),
        summary: new FormControl(''),
        address1: new FormControl(''),
        address2: new FormControl(''),
        address3: new FormControl(''),
        city: new FormControl(''),
        radius: new FormControl(''),
        email: new FormControl(''),
        availability: new FormControl(''),
        duration: new FormControl(''),
        postcode: new FormControl(''),
        telephone: new FormControl(''),
        url : new FormControl(''),
        categories : new FormControl(''),
        areas : new FormControl(''),  
        inperson: new FormControl(''),
        remote: new FormControl(''),
        ageserved: new FormControl(''),
        genderspecific: new FormControl(''),
        cost: new FormControl(''),
        bame: new FormControl(''),
        languages: new FormControl(''),
        starthour: new FormControl(''),
        startminutes: new FormControl(''),
        endhour: new FormControl(''),
        endminutes: new FormControl(''),
        mon: new FormControl(''),
        tue: new FormControl(''),
        wed: new FormControl(''),
        thu: new FormControl(''),
        fri: new FormControl(''),
        sat: new FormControl(''),
        sun: new FormControl(''),
   });

  textMiles = 'miles';

  public lastVisible = null;

  constructor(
    private servicesService:ServicesService, 
    private providersService: ProvidersService, 
    private categoryService: CategoryService,
    private userService: UserService,
    private http: HttpClient
    ) { }

  ngOnInit() {
    this.getservices();
    this.getProviders();
    this.getCategories();
    this.genders = Gender;
    this.bame = Bame;
    this.languages = Languages;
    this.ageserved = AgeRanges;
  }

  getCategories () {

    this.categoryService.query().subscribe((res) => {
      this.mycategories = res;
      return { res } as Category;
    })

  }

  pageforward() {
    this.getservices();
  }


  getservices(){

    this.servicesService.query().subscribe((res: Array<any>) => {
      this.loading = false;
      this.total = res.length;
      console.log(res);
      this.services = res;
    })

  }

  private getProviders(): void {
    this.providersService.getProviders().subscribe((providers) => {
      this.providers = providers;
    });
  }

  defaultFields() {
    
    let selectedProviderId = this.form.value.provider;
    
    for( var provider of this.providers) {
      if(provider.id == selectedProviderId) {
        this.form.patchValue( {
          address1: provider.address1, 
          address2: provider.address2, 
          address3: provider.address3,
          city: provider.city,
          telephone: provider.telephone,
          email: provider.email,
          url: provider.url,
          postcode: provider.postcode});
      }
    }
  }


  get openModal() {
    return this.addserviceModal;
  }

  set openModal(value: boolean) {
    this.addserviceModal = value;
    this.editMode = false;
    if (!value) {
      // Put your cleanup code here
      this.selectedAreas.splice(0);
      this.form.reset();
    }
  }

  pluralMiles() {
    // console.log(this.servicesService.form.value.radius);
    if(this.form.value.radius == "1") {
      this.textMiles = "mile";
    } else {
      this.textMiles = "miles";
    }
  }

  onAddArea(category, area) {
    console.log("Category " + category.name + " " + area.name);
    this.selectedAreas.push({ category: category.name, name: area.name, categoryid: category.id, areaid: area.id });
  }

  onDeleteArea(index) {
    console.log("Delete area - " + index);
    this.selectedAreas.splice(index, 1);
  }

  onDeleteSlot(index) {
    console.log("Delete slot - " + index);
    this.slots.splice(index, 1);
  }

  onAddSlot() {
    let days=[];
    if(this.form.value.mon == true) days.push("Mon");
    if(this.form.value.tue == true) days.push("Tue");
    if(this.form.value.wed == true) days.push("Wed");
    if(this.form.value.thu == true) days.push("Thu");
    if(this.form.value.fri == true) days.push("Fri");
    if(this.form.value.sat == true) days.push("Sat");
    if(this.form.value.sun == true) days.push("Sun");

    console.log("Slot found" + JSON.stringify(this.form.value) + " " + this.form.value.starthour + ":" + this.form.value.startminutes);
    console.log(days);

    for (const aday of days) {
      let slot = {
        day: aday, 
        starthour: this.form.value.starthour, 
        startminutes: this.form.value.startminutes, 
        endhour: this.form.value.endhour, 
        endminutes: this.form.value.endminutes
      };
      console.log("Slot added " + aday + " " + this.form.value.starthour + ":" + this.form.value.startminutes);
      this.slots.push(slot);
    }

  }

  onEdit(id){
    console.log(id);
    this.servicesService.get(id)
      .subscribe((res) => {
         this.detailedserviceId = id;
         let data = res;
         console.log(data);

         data.inperson = data.inperson === true ? "true" : "false";
         data.remote = data.remote === true ? "true" : "false";

         if(!data.availability) data.availability = [];
         if(!data.ageserved) data.ageserved = [];
         if(!data.bame) data.bame = [];

         let bame = [];
         res.bame.forEach((item) => {
            bame.push(String(item.id));
         });

         let ageserved = [];
         res.ageserved.forEach((item) => {
            ageserved.push(String(item.id));
         });

         let languages = [];
         res.languages.forEach((item) => {
            languages.push(String(item.id));
         });

         console.log(this.ageserved);
         console.log(ageserved);

         data.bame = bame;
         data.ageserved = ageserved;
         data.languages = languages;

         if(!data.radius) data.radius = 0;
         if(!data.type) data.type = "";
         if(!data.duration) data.duration = "";
         if(!data.genderspecific) data.genderspecific = "";
        
         delete data.completed;
         delete data.opentimes;
         delete data.geocode;
         delete data.boost;
         delete data.deactivated;
         delete data.created_by;
         delete data.updated_by;
         delete data.created_at;
         delete data.updated_at;  

         data.mon = "";
         data.tue = "";
         data.wed = "";
         data.thu = "";
         data.fri = "";
         data.sat = "";
         data.sun = "";

         data.starthour = "";
         data.startminutes = "";
         data.endhour = "";
         data.endminutes = "";

         console.log(data);
         this.form.setValue(data);
         this.providername = data.provider.name;
         this.form.controls.provider.setValue(data.provider.id);
         console.log(this.form.controls.provider);
         
         for(let i=0; i < data.areas.length; i++) {
           this.selectedAreas.push({ category: data.categories[i].name, name: data.areas[i].name, categoryid: data.categories[i].id, areaid: data.areas[i].id });
         } // TBD if categories don't equal areas!! error in index! - might want to look up
         
         this.slots = data.availability;

         this.editMode = true;
         this.addserviceModal = true;
       }, (err) => {
         console.warn(err)
       });
  }

  compareProviderId(pid1, pid2): boolean {
    console.log("Pid1 " + pid1);
    console.log("Pid2 " + pid2);

    return pid1 === pid2.id;
  }

  onDelete(id) {
    this.servicesService.delete(id)
      .subscribe((res)=>{
        console.log('Deleted')
        console.log(res)

      }),
      (err) => {
        console.warn(err)
      }
  } 


  onActivate(id, value) {
    if(value == 0) { 
      value = 1;
    } else {
      value = 0;
    }
    this.servicesService.toggleActivate(id, value)
      .subscribe((res)=>{
        console.log('Activate/Deactivate')
        console.log(res)

      }),
      (err) => {
        console.warn(err)
      }
  }

  onBoost(id, value) {
    if(value == 0) { 
      value = 1;
    } else {
      value = 0;
    }
    this.servicesService.toggleBoost(id, value)
      .subscribe((res)=>{
        console.log('Boost/Normal')
        console.log(res)

      }),
      (err) => {
        console.warn(err)
      }
  }

  onBury(id) {

    let value = -1;

    this.servicesService.toggleBoost(id, value)
      .subscribe((res)=>{
        console.log('Bury')
        console.log(res)

      }),
      (err) => {
        console.warn(err)
      }
  }  

  public openAdd() {
    // this.createNew();
    this.addserviceModal = true;
  } 

  public save() {

    let submittedCategories = [];
    let submittedAreas = [];

    // for(let i=0; i < this.selectedAreas.length; i++) {
    //   submittedAreas.push(this.selectedAreas[i].name);
    // }

    // for(let i=0; i < this.selectedAreas.length; i++) {
    //   submittedCategories.push(this.selectedAreas[i].category);
    // }

    for(let i=0; i < this.selectedAreas.length; i++) {
      submittedAreas.push(this.selectedAreas[i].areaid);
    }

    for(let i=0; i < this.selectedAreas.length; i++) {
      submittedCategories.push(this.selectedAreas[i].categoryid);
    }

    this.form.value.areas = submittedAreas;
    this.form.value.categories = submittedCategories;

    this.form.value.availability = this.slots;

    let data = this.form.value;

    delete data.mon;
    delete data.tue;
    delete data.wed;
    delete data.thu;
    delete data.fri;
    delete data.sat;
    delete data.sun;

    delete data.starthour;
    delete data.startminutes;
    delete data.endhour;
    delete data.endminutes;
    delete data.completed;

    console.log(data);

    this.servicesService.create(data)
    .subscribe((res)=> {
      console.log("service created")
      this.getservices();
      this.addserviceModal = false;
      this.selectedAreas.splice(0);
      this.slots.splice(0);
      this.form.reset();
      //this.rebuildFE();
    }),
    (err)=> {
      console.log("Error creating service")
      console.warn(err)
    };
      
  }

  public update() {

    let submittedCategories = [];
    let submittedAreas = [];

    // for(let i=0; i < this.selectedAreas.length; i++) {
    //   submittedAreas.push(this.selectedAreas[i].name);
    // }

    // for(let i=0; i < this.selectedAreas.length; i++) {
    //   submittedCategories.push(this.selectedAreas[i].category);
    // }

    for(let i=0; i < this.selectedAreas.length; i++) {
      submittedAreas.push(this.selectedAreas[i].areaid);
    }

    for(let i=0; i < this.selectedAreas.length; i++) {
      submittedCategories.push(this.selectedAreas[i].categoryid);
    }

    this.form.value.areas = submittedAreas;
    this.form.value.categories = submittedCategories;

    this.form.value.availability = this.slots;
    
    let data = this.form.value;

    delete data.mon;
    delete data.tue;
    delete data.wed;
    delete data.thu;
    delete data.fri;
    delete data.sat;
    delete data.sun;

    delete data.starthour;
    delete data.startminutes;
    delete data.endhour;
    delete data.endminutes;
    delete data.completed;

    this.servicesService.update(this.detailedserviceId, data)
     .subscribe((res)=> {
        console.log("service updated")
        this.selectedAreas.splice(0);
        this.slots.splice(0);
        this.form.reset();
        this.getservices();
        this.addserviceModal = false;
        this.detailedserviceId = null;
        // this.rebuildFE();
    }),
    (err)=> {
      console.log("Error creating service")
      console.warn(err)
    };

  }

  public cancel() {
    this.getservices();
    this.slots.splice(0);
    this.selectedAreas.splice(0);
    this.form.reset();
    this.addserviceModal = false;
  }

  private rebuildFE() {

    this.http.post("https://api.netlify.com/build_hooks/5f7b43df3e2aa9012005a00f",
    {})
    .subscribe(
        (val) => {
            console.log("POST call to rebuild front end content success", 
                        val);
        },
        response => {
            console.log("POST call rebuild front end content error", response);
        },
        () => {
            console.log("The POST rebuild front end content observable is now completed.");
        });

  }

}
