export interface Demographics {
  agesserved  : any;
  genderspecific: string;
}

export interface Overview {
  description: string;
}

export interface Needs {
  questions: string[];
}

export interface TrainingCase {
  demographics: Demographics;
  overview: Overview;
  needs : Needs;
}

export const CaseQuestions = [
  {
    name: 'Build resilience',
    text: 'Keep your mind in shape by increasing creative, physical and relationships',
    priority: 100
  },
  {
    name: 'Relationship and self-esteem',
    text: 'Including anger, bereavement, bullying, family, loneliness and transgender',
    priority: 100
  },
  {
    name: 'Anxiety',
    text: 'Including agoraphobia, panic attacks and stress',
    priority: 100
  },
  {
    name: 'Insomnia',
    text: 'Including sleep deprivation and sleep trouble',
    priority: 100
  },
  {
    name: 'Depressed',
    text: 'Including postnatal depression and seasonal affective disorder',
    priority: 100
  },
  {
    name: 'Hallucinations and hearing voices',
    text: 'Including paranoia, psychosis and schizophrenia',
    priority: 100
  },
  {
    name: 'Self care',
    text: 'Including chronic fatigue syndrome, debt, dissociation and hoarding',
    priority: 100
  },
  {
    name: 'Eating disorder',
    text: 'Including anorexia, bulimia and pica',
    priority: 100
  },
  {
    name: 'Memory and learning difficulties',
    text: 'Including aspergers, autims and dementia',
    priority: 100
  },
  {
    name: 'Self harm',
    text: 'Including suicide and suicidal thoughts',
    priority: 100
  },
  {
    name: 'Abuse',
    text: 'Including domestic violence, drug abuse and trauma',
    priority: 100
  },
  {
    name: 'Mood swings',
    text: 'Including bipolar and mania',
    priority: 100
  },  
];