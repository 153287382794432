import { Pipe, PipeTransform } from '@angular/core';
import { ProvidersService } from '../core/services/providers.service';

@Pipe({name: 'providerName', pure: false})
export class ProviderNamePipe implements PipeTransform {

	private providerName = 'Unknown';
	private cachedProviderId = '';

	constructor(private providersService: ProvidersService) { }

	transform(providerid: string): string {

		if(providerid !== this.cachedProviderId) {
			
			this.cachedProviderId = providerid;

			this.providersService.getProviders().subscribe((providers: Array<any>) => {
				for(let i=0; i < providers.length; i++) {
					
	           		if(providers[i].id === providerid) {
	           			this.providerName = providers[i].name;
	           		}
	           	}
			});
		}
		return this.providerName;

	}
}

@Pipe({name: 'toGender'})
export class GenderPipe implements PipeTransform {
	transform(gender): string {
		if(gender == "A") return "All";
		if(gender == "F") return "Female only";
		if(gender == "M") return "Male only";
		if(gender == "" || !gender) return "Not specific";
	}
}

@Pipe({name: 'toNA'})
export class NAPipe implements PipeTransform {
	transform(text: string): string {

		if(text == "" || !text) {
			return "Not specified";
		} else {
			return text;
		}

	}
}

@Pipe({name: 'toCharacter'})
export class CharacterPipe implements PipeTransform {

	transform(asciicode): string {
		return String.fromCharCode(asciicode + 64);
	}
}

@Pipe({name: 'spaceCSV'})
export class SpaceCSVPipe implements PipeTransform {

	transform(csv): string {
		
		if(csv == "" || !csv) { 
			return ""; 
		} else {
			let output = '';
			for(let i=0; i < (csv.length); i++) {
				output = output + csv[i] + ", ";
			}

			output = output.substr(0, output.length-2);

			return output;
		}
	}
}