import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { User, UserService, Userdata } from '../services/user.service';

@Component({
  templateUrl: './signup.component.html',
  styleUrls: ['./signup.component.css']
})
export class SignupComponent {
  form = {
    email: '',
    password: ''
  };
  error = null;

  public showVerify: boolean = false;

  constructor(private router: Router, private userService: UserService) {}

  signup() {
    this.error = null;
    let tmpuser = { name: this.form.email, email: this.form.email };

    this.userService.SignUp(this.form.email, this.form.password).subscribe(
      (res: Userdata) => {
        this.userService.user = res;
        //this.userService.SendVerificationMail();
        this.showVerify = true;
      },
      (error) => {
        this.error = {};
        this.error.message = "The email and password provided are not valid"
      }
    );
  }

}
