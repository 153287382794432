export const AgeRanges = [
  {
    id: '1',
    name: '<16',
    min: 0,
    max: 16
  },
  {
    id: '2',
    name: '16-18',
    min: 16,
    max: 18
  },
  {
    id: '3',
    name: '19-24',
    min: 19,
    max: 24
  },
  {
    id: '4',
    name: '25-34',
    min: 25,
    max: 34
  },
  {
    id: '5',
    name: '35-54',
    min: 35,
    max: 54
  },
  {
    id: '6',
    name: '55-74',
    min: 55,
    max: 74
  },
  {
    id: '7',
    name: '75+',
    min: 75,
    max: 1000
  }
];

export const Gender = [
  {
    id: 'M',
    name: 'Male'
  },
  {
    id: 'F',
    name: 'Female'
  },
  {
    id: 'A',
    name: 'All'
  }
];


export const Bame = [
  {
    id: '1',
    name: 'None'
  },
  {
    id: '2',
    name: 'African'
  },
  {
    id: '3',
    name: 'Afro Caribbean'
  },
  {
    id: '4',
    name: 'Black'
  },
  {
    id: '5',
    name: 'Central European'
  },
  {
    id: '6',
    name: 'Ethnic'
  },
  {
    id: '7',
    name: 'Minority'
  },
  {
    id: '8',
    name: 'South Asian'
  }  
];

export const Languages = [
  {
    id: '1',
    name: 'English'
  },
  {
    id: '2',
    name: 'Bengali'
  },
  {
    id: '3',
    name: 'Gujurati'
  },
  {
    id: '4',
    name: 'Hindi'
  },
  {
    id: '5',
    name: 'Hindko'
  },
  {
    id: '6',
    name: 'Polish'
  },
  {
    id: '7',
    name: 'Urdu'
  }  
];