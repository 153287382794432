import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ReactiveFormsModule, FormsModule } from '@angular/forms';
import { ClarityModule } from '@clr/angular';
import { CoreModule } from '@app/core';

import { CategoryRoutingModule } from './category-routing';
import { CategoryComponent } from './category/category.component';

@NgModule({
  imports: [
    CommonModule,
    FormsModule,
    ReactiveFormsModule,
    CoreModule,
    ClarityModule,
    CategoryRoutingModule
  ],
  declarations: [CategoryComponent]
})
export class CategoryModule {}