import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ReactiveFormsModule, FormsModule } from '@angular/forms';
import { ClarityModule } from '@clr/angular';
import { CoreModule } from '@app/core';

import { TrainingRoutingModule } from './training-routing';
import { TrainingComponent } from './training/training.component';
import { CasesComponent } from './cases/cases.component';
import { CharacterPipe, ProviderNamePipe, SpaceCSVPipe, GenderPipe, NAPipe } from './training.pipe';

@NgModule({
  imports: [
    CommonModule,
    FormsModule,
    ReactiveFormsModule,
    CoreModule,
    ClarityModule,
    TrainingRoutingModule
  ],
  declarations: [TrainingComponent, CasesComponent, CharacterPipe, ProviderNamePipe, SpaceCSVPipe, GenderPipe, NAPipe]
})
export class TrainingModule {}