import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { HttpHeaders, HttpClient } from '@angular/common/http';
import { Category } from '../models/category';
import { environment } from '../../../environments/environment';

import { UserService } from './user.service';

@Injectable()
export class CategoryService {

  constructor(private userService: UserService, private http: HttpClient) {
  }

  /* 
   * @description   Get a list of of categories
   */

  // public create(category: Category) {
  //   let timestamp = new Date().toISOString();
  //   this.firestore.collection("audit").add({collection: 'categories', action: 'create', ts: timestamp, userid: this.userService.user.user.email,
  //            ...category});
  //   return this.firestore.collection("categories").add(category);
  // }

  
  public query() {
    console.log('Get all categories');

    const headers: HttpHeaders = new HttpHeaders({
      'Content-Type': 'application/json',
      'Authorization': 'Bearer ' + this.userService._token,
      'Accept': '*'
    });

    return this.http.get(environment.apiHostUrl + 'categories', { headers: headers});
  }

  public get(id: string) : Observable <any> {
    console.log('Get a category');

    const headers: HttpHeaders = new HttpHeaders({
      'Content-Type': 'application/json',
      'Authorization': 'Bearer ' + this.userService._token,
      'Accept': '*'
    });

    return this.http.get(environment.apiHostUrl + 'categories/' + id, { headers: headers});
  }

  // public update(id: string, category: Category) : Promise<void> {
  //   let timestamp = new Date().toISOString();
  //   this.firestore.collection("audit").add({collection: 'categories', action: 'update', ts: timestamp, userid: this.userService.user.user.email,
  //            ...category});
  //   return this.firestore.collection("categories").doc(id).set(category);
  // }

  // public delete(id: string) : Promise<void> {
  //   let timestamp = new Date().toISOString();
  //   this.firestore.collection("audit").add({collection: 'categories', action: 'delete', ts: timestamp, userid: this.userService.user.user.email, objectid: id});
  //   return this.firestore.collection("categories").doc(id).delete();
  // }
}