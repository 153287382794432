import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { User, UserService } from '../services/user.service';


@Component({
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.css']
})
export class LoginComponent {
  form = {
    email: '',
    password: ''
  };
  error = null;

  constructor(private router: Router, private userService: UserService) {}

  login() {
    this.error = null;
    this.userService.login(this.form.email, this.form.password).subscribe(
      (res: User) => {
        console.log(res);
        this.userService.user = res.user;
        this.userService.token = res.jwt;
        this.userService.setRoles();

        this.router.navigateByUrl('/help');
      },
      error => {
        this.error = {};
        this.error.message = "The email and password provided are not valid"
      }
    );
  }

}
