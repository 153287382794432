import { Component, OnInit, ViewChild } from '@angular/core';
import { ClrWizard, ClrDatagridStateInterface } from "@clr/angular";
import { FormGroup, FormControl, FormArray, Validators} from "@angular/forms";

import {
  TrainingService,
  UserService,
  Gender,
  AgeRanges,
  CaseQuestions,
  TrainingCase
} from '@app/core';

// import { products } from '../products';

@Component({
  selector: 'app-cases',
  templateUrl: 'cases.component.html',
  styleUrls: ['cases.component.css']
})
export class CasesComponent implements OnInit {
  @ViewChild("wizardxl", {static: true}) wizard: ClrWizard;

  trainings;
  selected = [];
  detailedtrainingId;

  addtrainingModal = false;
  editMode = false;

  trainingresultsModal = false;

  loading = true;
  perPage = 50;
  page = 1;
  total = 0;

  genders;
  ageserved;
  casequestions;

  public trainModal = false;
  public editTrainMode = false;
  public serviceLoading = true;
  public case: TrainingCase = { overview: { description: "" }, 
                                needs: { questions: [] }, 
                                demographics:  { genderspecific: "", agesserved: [] } 
                               };

  description;

  // for presentation of service options during training
  services;
  numServices = 0;
  public currentService = { index: 0, id: "", name: "", summary: "", genderspecific: "", languages: "", ageserved: "", postcode: "", duration: "" };
  public servicesPlus = { count: 0, services: [] };
  public servicesMinus = { count: 0, services: [] };
  public servicesDefinite = { count: 0, services: [] };

  overview = new FormGroup({    
        id: new FormControl(''),    
        description: new FormControl(''),
  });


  demographics = new FormGroup({        
        genderspecific: new FormControl(''),
        id: new FormControl(''), 
        ageserved: new FormControl(''),
  });

  needs = new FormGroup({   
        id: new FormControl(''), 
        questions: new FormArray([ 
          new FormControl(''),
        ]),
  });

  constructor(private trainingService:TrainingService, private userService: UserService) { 

  }

  ngOnInit() {

    (this.needs.get("questions") as FormArray)['controls'].splice(0);

    for(let i=0; i < CaseQuestions.length; i++) {
      let needsFrmControl = new FormControl();
      this.questions.push(needsFrmControl);
    }

    this.description = "";

    this.genders = Gender;
    this.ageserved = AgeRanges;
    this.casequestions = CaseQuestions;
    this.getTraining();
  }

  get questions(): FormArray { return this.needs.get('questions') as FormArray; }

  get openModal() {
    return this.addtrainingModal;
  }

  set openModal(value: boolean) {
    console.log("Training modal");
    this.addtrainingModal = value;
    if (!value) {
      // Put your cleanup code here
      // this.overview.reset();
      // this.demographics.reset();
      // this.needs.reset();
    }
  }

 get openTrainingResultsModal() {
   return this.trainingresultsModal;
 }

 set openTrainingResultsModal(value: boolean) {
     console.log("Open training results");
     this.trainingresultsModal = value;
     if(!value) {
       // clean up code
     }
 }

  getTraining(){
    this.trainingService.query().subscribe((actionArray: Array<any>) => {
         this.total = actionArray.length;
         this.trainings = actionArray;
         this.loading = false;
    })
  }

  doFinish(): void {
      console.log(this.overview.value);
      console.log(this.demographics.value);
      console.log(this.needs.value);
      console.log("Finished");

      if(this.editMode) {
        this.onUpdate();
      } else {
        this.onSave();
      }
      
  }

  doNext(): void {
      console.log(this.overview.value);
      console.log(this.demographics.value);
      console.log(this.needs.value);
      console.log("Next");
  }

  onEdit(id){

    this.wizard.reset();

    this.trainingService.get(id)
      .subscribe((res: TrainingCase) => {
         this.detailedtrainingId = id;

         this.overview.patchValue({ id: id,...res.overview });
         this.demographics.patchValue({ id: id,...res.demographics});
         this.needs.patchValue({ id: id,...res.needs});

         this.editMode = true;
         this.addtrainingModal = true;

       }, (err) => {
         console.warn(err)
       });
  }


  public onResults(training){


    let serviceResults = { definite: [], maybe: [], no: []};
    this.case = training;


    this.trainingService.serviceResults(training).subscribe((services: Array<any>) => {

      this.numServices = services.length;

      // add in the counts of training
      services.forEach((service : any) => {
        if(service.vote == 1)  serviceResults.definite.push(service);
        if(service.vote == 0)  serviceResults.maybe.push(service);
        if(service.vote == -1)  serviceResults.no.push(service);
      });

      this.servicesDefinite.count = serviceResults.definite.length;
      this.servicesPlus.count = serviceResults.maybe.length;
      this.servicesMinus.count = serviceResults.no.length;

      this.services = serviceResults.definite;

      this.serviceLoading = false;
      this.trainingresultsModal = true;
      console.log(services);

    });

  }

  onDelete(id) {
    // this.trainingService.delete(id)
    //   .then((res)=>{
    //     console.log('Deleted');
    //     console.log(res);
    //     this.overview.reset();
    //   })
    //   .catch((err) => {
    //     console.warn(err)
    //   })

  } 

  public openAdd() {

    this.wizard.reset();
    this.overview.reset();
    this.demographics.reset();
    this.needs.reset();

    this.addtrainingModal = true;
    this.editMode = false;
  } 


  public onSave() {
    let data = { 
        overview: this.overview.value, 
        demographics: this.demographics.value, 
        needs : this.needs.value
    };

    console.log(data);
    delete data.overview.id;
    delete data.demographics.id;
    delete data.needs.id;

    this.trainingService.create(data)
    .subscribe((res)=> {
      console.log("training created");
      this.getTraining();
      // this.overview.reset();
      this.addtrainingModal = false;
    }),
    ((err)=> {
      console.log("Error creating training case")
      console.warn(err)
    });

      
  }

  public onUpdate() {
    let data = { 
        overview: this.overview.value, 
        demographics: this.demographics.value, 
        needs : this.needs.value
    };

    console.log(data);

    let updateId = data.overview.id;
    delete data.overview.id;
    delete data.demographics.id;
    delete data.needs.id;

    this.trainingService.update(updateId, data)
     .subscribe((res)=> {
        console.log("training case updated");
        // this.overview.reset();
        this.getTraining();
        this.addtrainingModal = false;
        this.detailedtrainingId = null;
        this.editMode = false;
    }),
    ((err)=> {
      console.log("Error creating training case");
      console.warn(err);
    });

  }

  public onCancel() {
    this.getTraining();
    this.addtrainingModal = false;
    this.overview.reset();
    this.demographics.reset();
    this.needs.reset();
  }

}