import { Injectable } from '@angular/core';
import { HttpHeaders, HttpClient } from '@angular/common/http';
import { Observable, BehaviorSubject, throwError } from 'rxjs';
import { switchMap, catchError, map } from 'rxjs/operators';

import { Category } from '../models/category';
import { CaseQuestions, TrainingCase, Needs } from '../models/training';
import { environment } from '../../../environments/environment';

import { UserService } from './user.service';

@Injectable()
export class TrainingService {

  category$: BehaviorSubject<string|null>;

  constructor(private userService: UserService, private http: HttpClient) {
  }

  /* 
   * @description   Get a list of of training
   */

  public create(training) {
    console.log('Get all training');
    
    const headers: HttpHeaders = new HttpHeaders({
      'Content-Type': 'application/json',
      'Authorization': 'Bearer ' + this.userService._token,
      'Accept': '*'
    });

    return this.http.post(environment.apiHostUrl + 'cases', JSON.stringify(training), { headers: headers});
  }
  
  public query() {
    console.log('Get all training');

    const headers: HttpHeaders = new HttpHeaders({
      'Content-Type': 'application/json',
      'Authorization': 'Bearer ' + this.userService._token,
      'Accept': '*'
    });

    return this.http.get(environment.apiHostUrl + 'cases', { headers: headers});
  }

  public get(id: string) {
    console.log('Get a training case');

    const headers: HttpHeaders = new HttpHeaders({
      'Content-Type': 'application/json',
      'Authorization': 'Bearer ' + this.userService._token,
      'Accept': '*'
    });

    return this.http.get(environment.apiHostUrl + 'cases/' + id, { headers: headers});
    
  }

  public update(id: string, training) {
    console.log('Update a case');

    const headers: HttpHeaders = new HttpHeaders({
      'Content-Type': 'application/json',
      'Authorization': 'Bearer ' + this.userService._token,
      'Accept': '*'
    });

    return this.http.put(environment.apiHostUrl + 'cases/' + id, JSON.stringify(training), { headers: headers});
  }

  public delete(id: string) {
    console.log('Get all training');

    const headers: HttpHeaders = new HttpHeaders({
      'Content-Type': 'application/json',
      'Authorization': 'Bearer ' + this.userService._token,
      'Accept': '*'
    });

    return this.http.delete(environment.apiHostUrl + 'cases/' + id, { headers: headers});
  }

  public scoreNeeds(needs) : string[] {
      let retCategories = [];
      console.log(needs);
      for (let i=0; i < needs.length; i++) {
        if(needs[i] == "3") {
          retCategories.push(CaseQuestions[i].name);
        }
      }

     return retCategories;
  }

  public saveTraining(trainingscore) {

    console.log('Get all training');

    const headers: HttpHeaders = new HttpHeaders({
      'Content-Type': 'application/json',
      'Authorization': 'Bearer ' + this.userService._token,
      'Accept': '*'
    });

    return this.http.get(environment.apiHostUrl + 'training', { headers: headers});

    // console.log("Saving training scores to database");
    // let timestamp = new Date().toISOString();
    // this.firestore.collection("audit").add({collection: 'trainingscore', action: 'create', ts: timestamp, userid: this.userService.user.user.email,
    //          ...trainingscore});
    // console.log(trainingscore);
    // return this.firestore.collection("trainingscore").add(trainingscore);
  }

  public appOptions(training) {

    let category;

    for(let i=0; i < training.needs.questions.length; i++) {
      if(training.needs.questions[i] == '3')  category = CaseQuestions[i].name;
    }

    // var url = 'http://localhost:5001/mind-e93ee/us-central1/mobileapps?category=' + category;

    var url = 'https://us-central1-mind-e93ee.cloudfunctions.net/mobileapps?category=' + category;

    return this.http.get(url);

  }

  public async serviceOptions(training) {

    let providerList;
    let caseNeeds = { always: [], often: [], occasional: [] };

    for(let i=0; i < training.needs.questions.length; i++) {
      if(training.needs.questions[i] == '3')  caseNeeds.always.push(CaseQuestions[i].name);
      if(training.needs.questions[i] == '2')  caseNeeds.often.push(CaseQuestions[i].name);
      if(training.needs.questions[i] == '1')  caseNeeds.occasional.push(CaseQuestions[i].name);
    }

    // var providerRef = this.firestore.collection('providers');
    // providerRef.get().subscribe( providerList => {
    //     console.log(providerList);
    // });

    
    var docRef = [];

    // var docRef = this.firestore.collection('services');

    // get the training case from the id passed in
    let qryRef = [];
    let returnData = { status: "200", count: 0, data: [] };
    
    console.log("case needs");
    console.log(caseNeeds);

    // for(let i=0; i < caseNeeds.always.length; i++) {
    //   console.log(caseNeeds.always[i]);
    //   qryRef.push(this.firestore.collection('services', (ref)=> ref.where('categories', 'array-contains', caseNeeds.always[i])).get().toPromise());
    // }

    // for(let i=0; i < caseNeeds.often.length; i++) {
    //   console.log(caseNeeds.often[i]);
    //   qryRef.push(this.firestore.collection('services', (ref)=> ref.where('categories', 'array-contains', caseNeeds.often[i])).get().toPromise());
    // }

    // for(let i=0; i < caseNeeds.occasional.length; i++) {
    //   console.log(caseNeeds.occasional[i]);
    //   qryRef.push(this.firestore.collection('services', (ref)=> ref.where('categories', 'array-contains', caseNeeds.occasional[i])).get().toPromise());
    // }

    //qryRef.push(this.firestore.collection('services', (ref)=> ref.where('categories', 'array-contains', "Relationship and self-esteem")).get().toPromise())

    let snapshots;
    let numDocs=0;
    let indexServices = [];

    try {
       snapshots = await Promise.all(qryRef);
       snapshots.forEach((results) => {
        results.forEach((doc)=> {    
            if(!indexServices.includes(doc.id)) {
              indexServices.push(doc.id);
              numDocs++;
              let summaryData;
              summaryData = { name: doc.data().name, 
                summary: doc.data().summary, 
                postcode: doc.data().postcode, 
                ageserved: doc.data().ageserved,
                genderspecific: doc.data().genderspecific,
                languages: doc.data().languages,
                provider: doc.data().provider,
                areas: doc.data().areas };
              returnData.count = numDocs;
              returnData.data.push({ id: doc.id,...summaryData });
            }
        })
    });
    } catch (e) {
      console.warn(e)
    }

    return returnData;

  }

  public serviceResults(training) {

    // get the training case from the id passed in

    const headers: HttpHeaders = new HttpHeaders({
      'Content-Type': 'application/json',
      'Authorization': 'Bearer ' + this.userService._token,
      'Accept': '*'
    });

    return this.http.get(environment.apiHostUrl + 'trainingscores?case.id=' + training.id, { headers: headers});

  }

  public match(id: string) : Observable<any> {

    // Look at promise all example for combining query

    this.category$ = new BehaviorSubject('');

    let queryServices = this.category$.pipe(
    //   switchMap(category =>
    //     this.firestore.collection('services', ref => {
    //       let filter : firebase.firestore.CollectionReference | firebase.firestore.Query = ref;
    //       if (category) { ref.where('categories', 'array-contains', category) };
    //       return filter;
    //     }).valueChanges()
    //   )
    // );

    // this.firestore.collection('training').doc(id).valueChanges().subscribe((ev: TrainingCase) => {
      
    //   console.log(ev);

    //   let queryCategories = this.scoreNeeds(ev.needs.questions);
    //   console.log(queryCategories);
    //     for (let i=0; i < queryCategories.length; i++) {
    //       this.category$.next(queryCategories[i]);
    //     }
    //   }
    );
    
    return queryServices;

  }

}